<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card
          tile
          class="elevation-6 mt-15"
        >
          <v-toolbar
            dark
          >
            <v-toolbar-title>...</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
            >
              <v-alert
                v-if="error"
                type="error"
              >
                Niepoprawny użytkownik lub hasło
              </v-alert>
              <v-text-field
                v-model="firstName"
                prepend-icon="mdi-account"
                label="Imię"
                type="text"
                @keyup.enter="register"
              />
              <v-text-field
                v-model="lastName"
                prepend-icon="mdi-lock"
                label="Nazwisko"
                type="text"
                @keyup.enter="register"
              />
              <v-text-field
                v-model="email"
                prepend-icon="mdi-lock"
                label="Email"
                type="email"
                @keyup.enter="register"
              />
              <v-text-field
                v-model="password"
                prepend-icon="mdi-lock"
                label="Hasło"
                type="password"
                @keyup.enter="register"
              />
              <v-text-field
                v-model="password2"
                prepend-icon="mdi-lock"
                label="Powtórz hasło"
                type="password"
                @keyup.enter="register"
              />

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              to="/"
              tile
              dark
            >
              Wróć
            </v-btn>
            <v-btn
              @click="register"
              tile
              dark
              :loading="loading"
            >
              Zarejestruj
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      error: false,
      loading: false,
    };
  },
  methods: {
    register() {
      this.loading = true;
      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.email,
        email: this.email,
        password: this.password,
      };
      this.axios.post('/register', userData)
        .then(( {status } ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.logIn();
          this.loading = false;
        });
    },
    logIn() {
      this.loading = true;
      this.error = false;
      this.$store.dispatch(
        'auth/login',
        { username: this.email, password: this.password },
      ).then(
        () => {
          this.$router.push('/dashboard');
        },
      ).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
